import type { ClassValue } from 'clsx'
import type { FunctionComponent, PropsWithChildren, Ref } from 'react'

import { VRTBrand } from '@sporza/config'
import clsx from 'clsx'

import styles from './card.module.scss'

interface CardProps extends PropsWithChildren {
  link?: string
  withHover?: boolean
  highlight?: boolean
  target?: string
  className?: ClassValue
  brand?: VRTBrand | string
  ebaData?: Record<any, any>
  darkMode?: boolean
  innerRef?: Ref<HTMLElement>
  onClick?: (args?: any) => void
  tabIndex?: number
}

const Card: FunctionComponent<CardProps> = (
  {
    link,
    children,
    withHover = true,
    highlight = false,
    target ,
    className,
    darkMode,
    innerRef,
    onClick,
    tabIndex
  }
) => {
  const Tag = link || onClick
    ? 'a'
    : 'div'

  return <Tag
    tabIndex={tabIndex}
    className={clsx(
      styles.card,
      link && styles.withLink,
      link && withHover && styles.withHover,
      highlight && styles.highlight,
      darkMode && styles.dark,
      className
    )}
    href={link || (onClick && '#')}
    target={target}
    ref={innerRef}
    onClick={(e) => {
      if (onClick) {
        e.preventDefault()
        onClick && onClick()
      }
    }}
  >
    {children}
  </Tag>
}

export default Card

export type {
  CardProps
}
