import type { CSSProperties, FunctionComponent } from 'react'
import type { BannerClickArguments } from '@sporza/tracking/events'

import { Theme, ThemeConfig } from '@sporza/config'
import { useBannerImpression } from '@sporza/tracking'
import { bannerClickEvent } from '@sporza/tracking/events'
import { getPathName } from '@sporza/utils/urls'
import { useRef } from 'react'

import { Image } from '../../../molecules/image'
import { generateDefaultSrc } from '../../../molecules/image/helpers'

import styles from './ad-banner.module.scss'

interface AdBannerThemeConfig extends ThemeConfig {
  backgroundImageUrl?: string
  leftImageUrl?: string
  rightImageUrl?: string
  logoUrl?: string
  accentColor?: string
  texrtColor?: string
}

interface AdBannerProps {
  themeConfig?: AdBannerThemeConfig
  bannerTargetUrl?: string
  designSystemBaseUrl?: string
}

const AdBanner: FunctionComponent<AdBannerProps> = (
  {
    themeConfig,
    bannerTargetUrl
  }
) => {
  const getBannerContext = () => {
    switch(themeConfig?.theme) {
      case Theme.GenericLidl:
        return {
          bannerTitle: 'Lidl, verspartner van jouw peloton',
          componentName: 'Lidl - wielervoorjaar 2025',
          componentId: 'Lidl - wielervoorjaar 2025',
          targetPageUrl: bannerTargetUrl,
          targetPageName: getPathName(bannerTargetUrl),
          targetPageId: bannerTargetUrl,
          bannerAdvertiser: 'Lidl'
        }
      case Theme.GenericPlayMadness:
        return {
          bannerTitle: 'go play - masters of madness',
          componentName: 'mediaplayer-banner',
          targetPageUrl: bannerTargetUrl,
          targetPageName: getPathName(bannerTargetUrl),
          targetPageId: bannerTargetUrl,
          bannerAdvertiser: 'Go Play - MastersofMadness'
        }
        case Theme.PlayFormula1:
        return {
          bannerTitle: 'Play Sports, mis niks van de strafste competities',
          componentName: 'Play Sports - Formule 1',
          targetPageUrl: bannerTargetUrl,
          targetPageName: getPathName(bannerTargetUrl),
          targetPageId: bannerTargetUrl,
          bannerAdvertiser: 'Play Sports'
        }
      case Theme.GenericPlay:
      default:
        return {
          bannerTitle: 'play sports - één abonnement verandert alles',
          componentName: 'mediaplayer-banner',
          targetPageUrl: bannerTargetUrl,
          targetPageName: getPathName(bannerTargetUrl),
          targetPageId: bannerTargetUrl,
          bannerAdvertiser: 'Telenet Play'
        }
    }
  }

  const {
    backgroundImageUrl,
    leftImageUrl,
    centerImageUrl,
    rightImageUrl
  } = themeConfig || {}

  const bannerRef = useRef(null)
  const bannerContext = getBannerContext()

  const trackBannerClick = (bannerContext: any) => {
    bannerClickEvent(bannerContext as BannerClickArguments)

    return true
  }

  useBannerImpression(
    {
      ref: bannerRef,
      ...bannerContext
    }
  )

  return <a
    className={styles.adBanner}
    style={backgroundImageUrl !== undefined ? { '--play-banner-background': `url("${generateDefaultSrc(`${backgroundImageUrl}`, 'width960hx')}") center` } as CSSProperties : undefined}
    href={bannerTargetUrl}
    target="_blank"
    rel="noreferrer"
    ref={bannerRef}
    title={bannerContext.bannerTitle}
    onClick={() => trackBannerClick(bannerContext)}
  >
    <div className={styles.left}>
      {leftImageUrl && <Image focalPoint={'Ml'} noCrop src={leftImageUrl} />}
    </div>
    <div className={styles.center}>
      {centerImageUrl && <Image noCrop src={centerImageUrl} />}
    </div>
    <div className={styles.right}>
      {rightImageUrl && <Image focalPoint={'Mr'} noCrop src={rightImageUrl} />}
    </div>
  </a>
}

export type {
  AdBannerProps
}

export {
  AdBanner
}
