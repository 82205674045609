import clsx from 'clsx'
import React, { FunctionComponent, PropsWithChildren, useRef } from 'react'

import Caption from '../../../atoms/caption'
import Category from '../../../atoms/category'
import Icon from '../../../atoms/icon'
import Title, { TitleSize } from '../../../atoms/title'
import { Icons } from '../../../design-tokens/iconography/icons'
import Button, { ButtonProps, ButtonSize } from '../../button'
import Image from '../../image'
import styles from '../program-item.module.scss'

interface ProgramItemDetailedProps extends PropsWithChildren {
  title?: string
  subTitle?: string
  label?: string
  image?: string
  description?: string
  channels?: ButtonProps[]
  link?: string
  icon?: Icons | string
  darkMode?: boolean
  entry?: IntersectionObserverEntry
}

const ProgramItemDetailed: FunctionComponent<ProgramItemDetailedProps> = (
  {
    title,
    subTitle,
    link,
    icon,
    image,
    label,
    channels,
    description,
    darkMode,
    entry
  }
) => {
  const isLargeEnough = entry?.intersectionRect && entry.intersectionRect?.width > 320
  const left = entry?.boundingClientRect && entry.intersectionRect
    && entry?.intersectionRect?.left - entry?.boundingClientRect?.left

  const style = left && isLargeEnough ? { left: left && 30 + left } : {}

  return <div className={clsx(
    styles.wrapper
  )}>
    <div className={clsx(
      styles.container,
      left && styles.sticky
    )}
         style={style}>
      <div className={styles.header}>
        {image && <Image src={image} className={styles.image}/>}
        <div className={styles.headerMid}>
          <Category>{label}</Category>
          <Caption darkMode={darkMode} className={styles.subTitle}>
            {icon && <Icon name={icon}/>} {subTitle}
          </Caption>
        </div>
        <div className={styles.headerRight}>
          {channels && channels.map((channel: ButtonProps, index) =>
            <Button key={`${index}-${channel.iconAfter}`} {...channel}
                    size={ButtonSize.small}
                    iconDarkMode={true}
            >ook op</Button>
          )}
        </div>
      </div>
      <div className={styles.content}>
        <Title darkMode={darkMode} size={TitleSize.XSmall} className={styles.title}>
          {link ? <a href={link}>{title}</a> : title}
        </Title>
        <Caption darkMode={darkMode} className={styles.description}>
          {link ? <a href={link}>{description}</a> : description}
        </Caption>
      </div>
    </div>
  </div>
}

export default ProgramItemDetailed
