import clsx from 'clsx'
import { format } from 'date-fns'
import React, { FunctionComponent, PropsWithChildren } from 'react'

import { EpgLayout } from '../../organisms/epg'
import { getOffsetPercentageByMinutes } from '../hourpicker'
import ProgramList, { ProgramListProps } from '../program-list'

import styles from './program-overview.module.scss'

interface ProgramOverviewProps extends PropsWithChildren {
  start?: Date
  end?: Date
  labels?: Date[]
  itemWidth?: number
  date?: Date
  items?: ProgramListProps[]
  now?: Date,
  darkMode?: boolean,
  layout?: EpgLayout
}

const ProgramOverview: FunctionComponent<ProgramOverviewProps> = (props) => {
  const { now = new Date() } = props

  const {
    labels = Array(24),
    itemWidth = 10,
    date = now,
    items = [],
    darkMode,
    layout
  } = props

  const start = new Date(format(date, 'yyyy-MM-dd 00:00:00'))
  const end = new Date(format(date, 'yyyy-MM-dd 23:59:59'))

  const positionStyles = itemWidth && labels.length ? {
    width: `${itemWidth * labels.length}rem`,
    transform: `translateX(-${getOffsetPercentageByMinutes(date, start, end)}%)`,
    marginLeft: '50%',
    transition: 'transform .2s ease-in-out'
  } : {}

  return <div className={clsx(
    styles.programOverview,
    darkMode && styles.darkMode
  )}>
    <div className={styles.wrapper} style={positionStyles}>
      {items.map((item, index) => <ProgramList
        key={`${index}-${item.title}-${item.subTitle}`}
        {...item}
        start={start}
        end={end}
        darkMode={darkMode}
        epgLayout={layout}
        />)}
    </div>
  </div>
}

export default ProgramOverview

export type {
  ProgramOverviewProps
}
