import { useApi } from '@sporza/hooks'
import clsx from 'clsx'
import { addMinutes, format, isToday } from 'date-fns'
import React, { FunctionComponent, useEffect, useState } from 'react'

import Title, { TitleElement, TitleSize } from '../../atoms/title'
import Filter, { FilterOption, FilterProps } from '../../molecules/filter'
import Hourpicker from '../../molecules/hourpicker'
import ProgramList, { ProgramListLayoutTypes, ProgramListProps } from '../../molecules/program-list'
import ProgramOverview from '../../molecules/program-overview'

import styles from './epg.module.scss'

enum EpgLayout {
  Default = 'default',
  Detailed = 'detailed'
}

interface EpgProps {
  title?: string
  day?: string
  itemWidth?: number
  items?: ProgramListProps[]
  filter?: FilterProps
  now?: Date
  sportApiUrl?: string
  tags?: string[]
  darkMode?: boolean,
  layout?: EpgLayout
}

const Epg: FunctionComponent<EpgProps> = (props) => {
  const [sportApiUrl, setSportApiUrl] = useState<string>()

  const { data, refetch } = useApi(props, {
    keyPrefix: 'epg',
    url: sportApiUrl
  })

  useEffect(() => {
    if (sportApiUrl) refetch()
  }, [sportApiUrl])

  const {
    title,
    itemWidth = 10,
    now = new Date(),
    filter,
    sportApiUrl: sportApiBaseUrl,
    tags,
    darkMode,
    layout = EpgLayout.Default
  } = props

  const {
    items,
  } = data

  const handleSelect = (option: FilterOption) => {
    const epgUrl = sportApiBaseUrl ? new URL(sportApiBaseUrl) : undefined
    epgUrl?.searchParams.set('date', option.value as string)
    tags?.length && epgUrl?.searchParams.set('tags', tags?.join(','))

    if (epgUrl) {
      setSportApiUrl(epgUrl?.toString())
    }
  }

  useEffect(() => {
    const startDate = getEarliestStartDate()
    if (startDate) setActive(isToday(startDate) ? now : startDate)
  }, [items])

  const getEarliestStartDate = () => {
    let start = items?.reduce((acc: any, list: ProgramListProps) => {
      list.items && list.items.forEach((item) => {
        if (item.start && (!acc || new Date(item.start) < acc)) {
          acc = new Date(item.start)
        }
      })

      return acc
    }, false)

    if (!start) return false

    if (isToday(start) && now < start) start = now

    const closestHour = start && new Date(format(start, 'yyyy-MM-dd HH:00:00'))
    return addMinutes(closestHour, +60)
  }

  const getLatestEndDate = () => {
    const end = items?.reduce((acc: any, list: ProgramListProps) => {
      list.items && list.items.forEach((item) => {
        if (item.end && (!acc || new Date(item.end) > acc)) {
          acc = new Date(item.end)
        }
      })

      return acc
    }, false)

    if (!end) return false

    const closestHour = new Date(format(end, 'yyyy-MM-dd HH:00:00'))
    return addMinutes(closestHour, 60)
  }

  const start = getEarliestStartDate()
  const end = getLatestEndDate()

  const [active, setActive] = useState<Date>(
    (start && isToday(start))
      ? now
      : start || now
  )

  const labels = active && Array(48) // label every half hour
    .fill(0)
    .map((value, index) => {
      const offsetMinutes = 30 * index
      return addMinutes(new Date(format(active, 'yyyy-MM-dd 00:00:00')), offsetMinutes)
    })

  // Adding this to the condition will show the component again when there is no data for a selected filter item
  // however the initial state of the filter and epg should not show these empty days
  if (!filter && (!start || !end)) return false

  const hasHeader = items?.some((item: any) =>
    item.title !== undefined
    || item.subTitle !== undefined
    || item.icon !== undefined
  )

  return <div
    className={clsx(
      styles.epg,
      darkMode && styles.darkMode
    )}
  >
    {title && <Title darkMode={darkMode} className={clsx(
      styles.title,
      !filter && styles.withMargin
    )} size={TitleSize.Large} tag={TitleElement.H2}>{title}</Title>}
    {filter &&
      <Filter {...filter}
              onSelect={handleSelect}
              hideWhenSingle={false}
              darkMode={darkMode}
      />}
    <Hourpicker
      setActive={setActive}
      setSportApiUrl={setSportApiUrl}
      labels={labels}
      itemWidth={itemWidth}
      date={active}
      now={now}
      sportApiUrl={sportApiBaseUrl}
      darkMode={darkMode}
    />
    {hasHeader && <div className={styles.headerWrapper}>
      {items?.map((item: ProgramListProps, index: number) =>
        <ProgramList
          key={`${index}-${item.title}-${item.subTitle}`}
          layout={ProgramListLayoutTypes.Header}
          darkMode={darkMode}
          {...item}
        />
      )}
    </div>}
    <ProgramOverview
      items={items}
      labels={labels}
      itemWidth={itemWidth}
      date={active}
      now={now}
      darkMode={darkMode}
      layout={layout}
    />
  </div>
}

export default Epg

export type {
  EpgProps
}

export {
  EpgLayout
}
