import clsx from 'clsx'
import React, { FunctionComponent, PropsWithChildren } from 'react'

import Caption, { CaptionWeight } from '../../../atoms/caption'
import Icon from '../../../atoms/icon'
import { Icons } from '../../../design-tokens/iconography/icons'
import styles from '../program-item.module.scss'

interface ProgramItemDefaultProps extends PropsWithChildren {
  title?: string
  subTitle?: string
  link?: string
  icon?: Icons | string
  darkMode?: boolean
}

const ProgramItemDefault: FunctionComponent<ProgramItemDefaultProps> = (
  {
    title,
    subTitle,
    link,
    icon,
    darkMode
  }
) => <>
    <Caption darkMode={darkMode} className={styles.subTitle}>
      {icon && <Icon name={icon} />} {subTitle}
    </Caption>
    <Caption darkMode={darkMode} weight={CaptionWeight.Medium} className={styles.title}>
      {link ? <a href={link}>{title}</a> : title}
    </Caption>
</>

export default ProgramItemDefault
