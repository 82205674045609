import type { FunctionComponent } from 'react'
import type { MediaplayerComponentProps } from '../../mediaplayer'
import type { BaseEventProps } from './base-event'

import clsx from 'clsx'

import Category from '../../../atoms/category'
import Icon from '../../../atoms/icon'
import Meta from '../../../atoms/meta'
import Title, { TitleElement, TitleSize } from '../../../atoms/title'
import Image from '../../../molecules/image'
import Pill from '../../../molecules/pill'
import Mediaplayer from '../../mediaplayer'
import { TimelineMode } from '../config'

import styles from './events.module.scss'

interface MediaplayerEventProps extends BaseEventProps {
  mediaplayer: MediaplayerComponentProps
}

const MediaplayerEvent: FunctionComponent<MediaplayerEventProps> = (
  {
    id,
    timestamp,
    label,
    subLabel,
    icon,
    title,
    text,
    image,
    mediaplayer,
    isEditable,
    isBelgian,
    mode = TimelineMode.Default,
  }
) => {
  const isDefault = mode === TimelineMode.Default
  const isTeaser = mode === TimelineMode.Teaser

  return <div
    className={clsx(
      isDefault && styles.event,
      isTeaser && styles.eventTeaser,
      isTeaser && styles.hasVideo,
      'sw-timeline-item'
    )}
    data-event-id={id}
    data-is-editable={isEditable ? true : undefined}
  >
    <span className={styles.eventAnchor} id={`timeline-${timestamp}`}></span>
    {
      isDefault
        ?
        <div className={styles.labelContainer}>
          {label && <Pill {...label} />}
        </div>
        : null
    }
    <div className={clsx(
      styles.eventContent
    )}>
      {
        icon || title || subLabel && isDefault
          ? <div className={styles.iconTitleContainer}>
            {
              isBelgian
                ? <Icon ariaLabel="Belg" name="os-2024-belg" />
                : null
            }
            {
              subLabel
              && isDefault
              && <Category className={styles.subLabel}>{subLabel.label}</Category>
            }
            {icon && <Icon {...icon} ariaHidden className={styles.icon}/>}
            {title && !isTeaser && <Title size={TitleSize.Small} tag={TitleElement.H3}>{title}</Title>}
          </div>
          : null
      }
      {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
      {image && <Image {...image} addToGallery={true} />}
      {mediaplayer && <Mediaplayer {...mediaplayer.componentProps} />}
    </div>
    {
      isTeaser
        ?
        <div className={styles.eventFooter}>
          {label?.label && <Meta iconBefore={label.iconBefore}>{label.label}</Meta>}
          {subLabel && <Pill {...subLabel} iconBefore={undefined} />}
        </div>
        : null
    }
  </div>
}

export default MediaplayerEvent

export type {
  MediaplayerEventProps,
}
