import clsx from 'clsx'
import React, { FunctionComponent } from 'react'

import Icon from '../../../atoms/icon'
import Meta from '../../../atoms/meta'
import Paragraph, { ParagraphWeight } from '../../../atoms/paragraph'
import { ProgramListProps } from '../index'
import styles from '../program-list.module.scss'

const ProgramListHeader: FunctionComponent<ProgramListProps> = ({
  title,
  subTitle,
  icon,
  darkMode
}) => {
  return <div className={clsx(
    styles.programList,
    styles.programListHeader,
    darkMode && styles.darkMode
  )}>
    <div className={styles.headerTitle}>
      <Paragraph weight={ParagraphWeight.Medium}>
        <span className={styles.livestream}>livestream </span>{title}
      </Paragraph>
      <Meta>{subTitle}</Meta>
    </div>
    {icon && <span className={styles.icon}>
        <Icon name={icon}/>
      </span>}
  </div>
}

export default ProgramListHeader
