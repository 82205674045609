import { useIntersectionObserver } from '@sporza/hooks'
import clsx from 'clsx'
import { differenceInMinutes, parse, startOfDay } from 'date-fns'
import React, { FunctionComponent, PropsWithChildren, ReactElement, useRef } from 'react'

import { Icons } from '../../design-tokens/iconography/icons'
import { EpgLayout } from '../../organisms/epg'
import { ButtonProps } from '../button'

import ProgramItemDetailed from './layouts/detailed'
import ProgramItemDefault from './layouts'
import styles from './program-item.module.scss'

interface ProgramItemProps extends PropsWithChildren {
  title: string
  subTitle: string
  description?: string
  link?: string
  label?: string
  image?: string
  channels?: ButtonProps[]
  start: string
  end: string
  icon?: Icons | string
  isLive?: boolean
  listStart?: Date
  listEnd?: Date,
  layout?: EpgLayout
  darkMode?: boolean
}

const ProgramItem: FunctionComponent<ProgramItemProps> = (props) => {
  const {
    isLive,
    start,
    end,
    listStart,
    listEnd,
    layout = EpgLayout.Default,
    darkMode
  } = props

  const ref = useRef(null)
  const entry = useIntersectionObserver(ref, {
    threshold: Array.from({ length: 101 }, (_, i) => i / 100)
  })

  const convertToMinutes = (timeString: string) => {
    const parsedTime = new Date(timeString)
    return differenceInMinutes(parsedTime, startOfDay(parsedTime))
  }

  const style = (listStart && listEnd) ? {
    gridColumnStart: convertToMinutes(start),
    gridColumnEnd: convertToMinutes(end)
  } : {}

  const layouts: { [Key in EpgLayout as string] : ReactElement } = {
    'default' : <ProgramItemDefault {...props} />,
    'detailed' : <ProgramItemDetailed {...props} entry={entry} />,
  }

  const Layout = layouts[layout || EpgLayout.Default]

  return <li ref={ref} className={clsx(
    styles.programItem,
    layout && styles[layout],
    isLive && styles.live,
    darkMode && styles.darkMode
  )}
  style={style}>
    {Layout}
  </li>
}

export default ProgramItem

export type {
  ProgramItemProps
}
