import type { FunctionComponent } from 'react'
import type { ScoreboardComponentProps } from '../../scoreboard'
import type { TabOption } from '../../tab'
import type { CalendarDataType, CalendarProps } from '../index'

import { debounce } from '@sporza/utils'
import { format } from 'date-fns'
import { useState } from 'react'

import Caption from '../../../atoms/caption'
import Icon from '../../../atoms/icon'
import Title, { TitleElement } from '../../../atoms/title'
import Datepicker from '../../../molecules/datepicker'
import Heading from '../../../molecules/heading'
import Well from '../../../molecules/well'
import LinkList from '../../link-list'
import Scoreboard from '../../scoreboard'
import Tab from '../../tab'
import styles from '../calendar.module.scss'
import { CalendarType } from '../index'

const Default: FunctionComponent<CalendarProps> = (
  {
    sportApiUrl,
    data,
    date,
    dates,
    title,
    type
  }
) => {
  const [currentData, setCurrentData] = useState<CalendarDataType>(data)
  const [active, setActive] = useState<number>(0)

  const updateData = async (date: Date) => {
    setActive(0)

    const url = new URL(sportApiUrl)
    url.searchParams.set('date', format(date, 'yyyy-MM-dd'))

    const newData = await fetch(url, {
      method: 'GET',
      timeout: 3000,
      mode: 'cors',
      credentials: 'include'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson.errorMessages && !responseJson.error) {
          return responseJson.componentProps.data
        }
        return null
      })
      .catch((error) => {
        console.error(error)
        return null
      })

    setCurrentData(newData)
  }

  const Matches: FunctionComponent<{data: ScoreboardComponentProps[]}> = ({ data }) => {
    let competitionName: any = ''
    return data.length === 0
      ? <li className={styles.empty}>
          <Title size={'Medium'}>Geen wedstrijden gepland</Title>
        </li>
      : data.map((match, index) => {
        const showHeader = competitionName !== match.componentProps.competitionName
        competitionName = match.componentProps.competitionName
        return <li key={index+JSON.stringify(match.componentProps)}>
          <Scoreboard showHeader={showHeader}
                      {...{ ...match.componentProps, showLinks: false }}
          />
        </li>
      })
  }

  const items: () => TabOption[] = () => {
    return currentData?.map((item) => {
      if (item.type === 'overview'){
        return {
          label: item.label,
          content: item.items?.map((overviewItem: any, index: number) => {
            switch (overviewItem.type) {
              case 'live':
                return <div key={`${item.label+index}`}>
                  <Title size={'Medium'}>{overviewItem.label}</Title>
                  <Well>
                    <ul className={styles.list}>
                      {overviewItem.items?.map((sportItem: any) => <>
                        <Matches data={sportItem.items}/>
                      </>)}
                    </ul>
                  </Well>
                </div>
              case 'LinkList':
                return <div key={`${item.label + index}`}>
                  <Title size={'Medium'}>{overviewItem.label}</Title>
                  <LinkList key={`${item.label + index}`} items={overviewItem.items.map((linkListItem: any) => {
                    return {
                      ...linkListItem,
                      onClick: () => setActive(currentData
                        .map((item: any) => item.label)
                        .findIndex((label: any) => label === linkListItem.title)
                      )
                    }
                  })}/>
                </div>
              default:
                return <div key={`${item.label+index}`}>
                  <Title size={'Medium'}>{overviewItem.label}</Title>
                  {overviewItem.items?.map((sportItem: any) => <>
                    <Well>
                      <ul className={styles.list}>
                        <li>
                          <Heading iconBefore={sportItem.icon}>{sportItem.label}</Heading>
                        </li>
                        <Matches data={sportItem.items}/>
                      </ul>
                    </Well>
                  </>)}
                </div>
            }
          })
        }
      } else {
        return {
          label: item.label,
          content: <Well>
            <ul className={styles.list}>
              <Matches data={item.items as ScoreboardComponentProps[]}/>
            </ul>
          </Well>
        }
      }
    })
  }

  const emptyPlaceholder = <div className={styles.empty}>
    <div className={styles.emptyLogo}>
      <Icon name={'soccer-start-whistle'} />
    </div>
    <div className={styles.emptyText}>
      <Title size={'Medium'}>Geen wedstrijden gepland</Title>
      <Caption>Op deze datum hebben alle sporters een rustdag. De ideale dag om <a href="https://sporza.be/nl/categorie/blijf-sporten/">zelf te sporten</a>?
      </Caption>
    </div>
  </div>

  return <>
    {title && <Title size={'Large'} tag={TitleElement.H1}>{title}</Title>}
    {type !== CalendarType.Today && <Datepicker date={date} dates={dates} onSelect={debounce(updateData, 200)} />}
    <Tab label={'toon'} items={items()} emptyPlaceholder={emptyPlaceholder} callback={setActive} activeIndex={active} />
  </>
}

export default Default

export type {
  CalendarProps
}
