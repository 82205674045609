import type { FunctionComponent, PropsWithChildren } from 'react'

import clsx from 'clsx'

import styles from './progress-bar.module.scss'

enum ProgressBarSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

interface ProgressBarProps extends PropsWithChildren {
  progress?: number
  size?: ProgressBarSize
}

const ProgressBar: FunctionComponent<ProgressBarProps> = (
  {
    progress,
    size = ProgressBarSize.Small,
  }
) => {
  return <div className={clsx(
    styles.progressBar,
    size === ProgressBarSize.Medium && styles.medium,
    size === ProgressBarSize.Large && styles.large,
    progress === undefined && styles.progressBarEmpty
  )}>
    <div className={styles.filler} style={{ width: `${progress}%` }} />
  </div>
}

export default ProgressBar

export {
  ProgressBarSize
}

export type {
  ProgressBarProps
}
