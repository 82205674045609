import type { FunctionComponent } from 'react'
import type { VotingProps } from '../../../voting'

import { useAuth, useInteractionService } from '@sporza/hooks'
import { useEffect } from 'react'
import { useState } from 'react'

import Paragraph from '../../../../../atoms/paragraph'
import Title, { TitleSize } from '../../../../../atoms/title'
import Button from '../../../../../molecules/button'
import Countdown from '../../../../../molecules/countdown'
import { VotingPhase } from '../../../config'
import { LoginButton } from '../components'
import { mapGetVoteToLineup } from '../helpers'
import commonStyles from '../iedereen-bondscoach.module.scss'

import styles from './countdown.module.scss'

type IedereenBondscoachCountdownProps = VotingProps & {
  isLive: boolean
}

const IedereenBondscoachCountdown: FunctionComponent<IedereenBondscoachCountdownProps> = (
  {
    phase,
    isLive,
    options,
    endDateTime,
    handlePhaseChange,
    className,
    votingBaseUrl,
    slug
  }
) => {
  const { isLoggedIn } = useAuth()
  const [isLiveSt, setIsLiveSt] = useState(isLive)

  const { vote, fetchVote, hasFetchedVote } = useInteractionService(votingBaseUrl, slug) || {}

  useEffect(() => {
    if (!hasFetchedVote && isLoggedIn && fetchVote) {
      fetchVote()
    }
  }, [hasFetchedVote, isLoggedIn, fetchVote])

  useEffect(() => {
    if (vote) {
      handlePhaseChange && handlePhaseChange(VotingPhase.results, mapGetVoteToLineup(vote, options))
    }
  }, [vote, handlePhaseChange])

  const isResults = phase === VotingPhase.results

  const title =
    isLiveSt || isResults
      ? 'iedereen bondscoach'
      : 'fijn dat je er al bent'

  const subTitle =
    isResults
      ? 'de stemming is helaas gesloten'
      : isLiveSt
        ? 'stel je ideale team samen!'
        : 'nog even geduld, we beginnen er zo aan'

  return <div className={className}>
    <div className={commonStyles.header}>
      <Title size={TitleSize.Large}>{title}<span className={commonStyles.accentPoint}>.</span></Title>
      <Paragraph>{subTitle}</Paragraph>
    </div>

    {
      isLiveSt
        ?
        isLoggedIn
          ? <Button
            className={commonStyles.ctaButton}
            iconAfter="chevron-right"
            onClick={() => handlePhaseChange && handlePhaseChange(VotingPhase.voting)}
          >
            stel mijn team samen
          </Button>
          : null
        : endDateTime
          ? <Countdown
            className={styles.countdownTimer}
            date={endDateTime as string}
            onComplete={() => setIsLiveSt(true)}
          />
          : null
    }

    <LoginButton/>
  </div>
}

export {
  IedereenBondscoachCountdown
}

export type {
  IedereenBondscoachCountdownProps
}

