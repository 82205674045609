import type { FunctionComponent } from 'react'
import type { VotingProps } from '../../../voting'
import type { Vote } from '@sporza/services'

import { useAuth, useInteractionService } from '@sporza/hooks'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

import Paragraph from '../../../../../atoms/paragraph'
import Title, { TitleSize } from '../../../../../atoms/title'
import Button, { ButtonSize } from '../../../../../molecules/button'
import { VotingPhase } from '../../../config'
import { Formation, Selection } from '../components'
import { mapPostVoteToLineup } from '../helpers'
import commonStyles from '../iedereen-bondscoach.module.scss'

interface IedereenBondscoachVotingProps extends VotingProps {
  options?: any[]
}

const maxPlayers = 11

const IedereenBondscoachVoting: FunctionComponent<IedereenBondscoachVotingProps> = (
  {
    votingId,
    slug,
    options,
    formations,
    defaultPlayers = [],
    handlePhaseChange,
    className,
    votingBaseUrl
  }
) => {
  const { createVote: createInteractionVote } = useInteractionService(votingBaseUrl, slug)

  const [selectedPlayersNum, setSelectedPlayersNum] = useState<number>(0)
  const [selectedPlayers, setSelectedPlayers] = useState<any[]>(defaultPlayers)
  const [selectedFormation, setSelectedFormation] = useState<string>('4-3-3')
  const [optionsSt, setOptionsSt] = useState(options)
  const [showField, setShowField] = useState(false)
  const [notification, setNotification] = useState<string>()
  const [reorderMode, setReorderMode] = useState(false)
  const [hasKeeper, setHasKeeper] = useState(false)
  const playersLeft = maxPlayers - selectedPlayersNum

  useEffect(() => {
    if (defaultPlayers?.length) {
      setSelectedPlayersNum(defaultPlayers.length)

      const updatedOptions = optionsSt
        ?.map((opt) => {
          const selected = defaultPlayers.some((player: any) =>
            player.interactionOptionId === opt.interactionOptionId
          )

          return {
            ...opt,
            selected
          }
        })

      setOptionsSt(updatedOptions)
      setHasKeeper(defaultPlayers.some((player: any) => player.function === 'KEEPER'))
    }
  }, [defaultPlayers])

  useEffect(() => {
    if (optionsSt) {
      setSelectedPlayers(optionsSt.filter((opt) => opt.selected))
      setHasKeeper(optionsSt.some((opt) => opt.selected && opt.function === 'KEEPER'))
    }
  }, [selectedPlayersNum, optionsSt])

  const toggleOption = (option: any) => {
    if (selectedPlayersNum < maxPlayers || option.selected) {
      const updatedOptions = optionsSt
        ?.map((opt) =>
          opt.name === option.name
            ? { ...opt, selected: !opt.selected }
            : opt
        )
      const updatedPlayerCount = selectedPlayersNum + (option.selected ? -1 : 1)

      setOptionsSt(updatedOptions)
      setSelectedPlayersNum(updatedPlayerCount)
    }
  }

  const createVote = async () => {
    setNotification(undefined)

    if (!votingId || !slug) {
      console.error('votingId or slug is missing')

      return
    }

    const selection = selectedPlayers
      ?.map((option) => option.interactionOptionId)

    const vote: Vote = {
      slug,
      entries: {
        kind: 'Single',
        entry: {
          kind: 'IdOnly',
          votingId,
          selection
        }
      },
      motivation: selectedFormation
    }

    if (createInteractionVote) {
      const responseStatus = await createInteractionVote(vote)

      if (responseStatus > 299) {
        setNotification('er is iets misgegaan, probeer het later opnieuw')
      } else {
        setNotification('bedankt voor je stem!')
        handlePhaseChange && handlePhaseChange(VotingPhase.results, mapPostVoteToLineup(vote, options))
      }
    }
  }

  return <div
    className={clsx(
      className
    )}
  >
    <div className={commonStyles.header}>
      <Title size={TitleSize.Large}>selecteer je spelers<span className={commonStyles.accentPoint}>.</span></Title>
      <Paragraph>
        {
          selectedPlayersNum < maxPlayers
            ? <>kies <strong>{playersLeft}</strong> {playersLeft == 1 ? 'speler' : 'spelers'} uit de selectie</>
            : showField
              ? 'in welke formatie wil jij je team zien spelen?'
              : 'wauw, wat een selectie!'
        }
      </Paragraph>
    </div>

    {
      !showField
      && <>
        <Selection
          players={optionsSt}
          playersLeft={playersLeft}
          togglePlayerSelection={toggleOption}
        >
          <Button
            className={commonStyles.ctaButton}
            onClick={() => setShowField(true)}
            iconAfter="chevron-right"
            disabled={(selectedPlayersNum < maxPlayers) || !hasKeeper}
          >volgende</Button>
        </Selection>
      </>
    }

    {
      showField
      && <Formation
        players={selectedPlayers}
        setPlayers={setSelectedPlayers}
        formations={formations}
        selectedFormation={selectedFormation}
        setSelectedFormation={setSelectedFormation}
        notification={notification}
        reorderMode={reorderMode}
      >
        <Button
          className={commonStyles.link}
          variant="tertiary"
          onClick={() => setReorderMode(!reorderMode)}
        >
          {
            reorderMode
              ? 'veldposities vastzetten'
              : 'veldposities aanpassen'
          }
        </Button>
        <Button
          className={commonStyles.ctaButton}
          onClick={() => createVote()}
          iconAfter="chevron-right"
          disabled={reorderMode}
        >bevestig mijn selectie</Button>
        <Button
          iconBefore="chevron-left"
          size={ButtonSize.small}
          onClick={() => setShowField(false)}
          className={commonStyles.backButton}
        >
          selectie
        </Button>
      </Formation>
    }
  </div>
}

export {
  IedereenBondscoachVoting
}

export type {
  IedereenBondscoachVotingProps
}

