import type { FunctionComponent } from 'react'
import type { IconProps } from '../../../atoms/icon'
import type { ImageComponentProps } from '../../../molecules/image'
import type { PillProps } from '../../../molecules/pill'

import clsx from 'clsx'

import Category from '../../../atoms/category'
import Icon from '../../../atoms/icon'
import Meta, { MetaWeight } from '../../../atoms/meta'
import Title, { TitleElement, TitleSize } from '../../../atoms/title'
import { scrollToElement } from '../../../molecules/field-timeline/event-set'
import Image from '../../../molecules/image'
import Pill from '../../../molecules/pill'
import { Owner, TimelineMode } from '../config'
import { TimelineType } from '../timeline'

import styles from './events.module.scss'

interface BaseEventProps {
  id: string
  timestamp: number
  label: PillProps
  subLabel?: PillProps
  icon: IconProps
  title: string
  text: string
  image: ImageComponentProps
  ariaLabel: string // TODO: add ariaLabel to the API
  timelineType: TimelineType
  owner: Owner
  autoLoad?: boolean
  isEditable?: boolean
  isBelgian?: boolean
  isHighlighted?: boolean
  mode?: TimelineMode
  link?: string
}

const BaseEvent: FunctionComponent<BaseEventProps> = (
  {
    id,
    timestamp,
    label,
    subLabel,
    icon,
    title,
    text,
    image,
    isEditable,
    isBelgian,
    mode = TimelineMode.Default,
    link,
    timelineType,
  }
) => {
  const isDefault = mode === TimelineMode.Default
  const isTeaser = mode === TimelineMode.Teaser
  const isTimelineTypeFull = timelineType === TimelineType.Full
  const shouldShowImageAsBackground = isTeaser && image?.componentProps?.src

  const overwriteStyles: Record<string, string> = {}

  if (shouldShowImageAsBackground) {
    overwriteStyles['backgroundImage'] = 'url(' + image?.componentProps?.src + ')'
  }

  return <div
    className={clsx(
      isDefault && styles.event,
      isTeaser && styles.eventTeaser,
      isTeaser && styles.isClickable,
      shouldShowImageAsBackground && styles.hasImage,
      'sw-timeline-item'
    )}
    data-event-id={id}
    data-is-editable={isEditable ? true : undefined}
    style={overwriteStyles}
    onClick={(e) => isTeaser && link && scrollToElement(e, `${link}#timeline-${timestamp}`)}
  >
    <span className={styles.eventAnchor} id={`timeline-${timestamp}`}></span>
    {
      isDefault
        ?
        <div className={styles.labelContainer}>
          {label && <Pill {...label} />}
        </div>
        : null
    }
    <div className={clsx(
      styles.eventContent,
      icon && styles.highlight
    )}>
      {
        (icon || title || subLabel && isDefault) && (!image || isDefault)
          ? <div className={styles.iconTitleContainer}>
            {
              isBelgian
                // TODO: add icon for generic belgian events
                ? <Icon ariaLabel="Belg" name="os-2024-belg" />
                : null
            }
            {
              subLabel
                && isDefault
                && <Category className={styles.subLabel}>{subLabel.label}</Category>
            }
            {icon && <Icon {...icon} ariaHidden className={styles.icon}/>}
            {title && <Title size={TitleSize.Small} tag={TitleElement.H3}>{title}</Title>}
          </div>
          : null
      }
      {text && <div
        className={clsx(
          isTeaser && styles.textTeaser,
          isTimelineTypeFull &&  styles.timelineTypeFull
        )}
        dangerouslySetInnerHTML={{ __html: text }}
      />}
      {
        image
        && isDefault
        && <Image {...image.componentProps} addToGallery={true}/>
      }
      {
        isTeaser
        && image && (image.componentProps?.caption || title)
        && <div className={styles.imageCaptionContainer}>
          <Meta
            className={styles.imageCaption}
            weight={title ? MetaWeight.Bold : undefined}
          >{image.componentProps?.caption || title}</Meta>
        </div>
      }
    </div>

    {
      isTeaser
        ?
        <div className={clsx(
          styles.eventFooter,
          icon && styles.highlightFooter
        )}>
          {label?.label && <Meta iconBefore={label.iconBefore}>{label.label}</Meta>}
          {subLabel && <Pill {...subLabel} iconBefore={undefined} />}
        </div>
        : null
    }
  </div>
}

export default BaseEvent

export type {
  BaseEventProps,
}
