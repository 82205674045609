enum ScoreboardLayoutTypes {
  Default = 'default',
  Simple = 'simple',
  Detailed = 'detailed',
  Compact = 'compact',
  Event = 'event',
  Banner = 'banner',
  Card = 'card',
}

enum ScoreboardContext {
  Detail = 'detail',
  Default = 'default',
  Matchday = 'matchday',
  Result = 'result',
  Article = 'article',
  Calendar = 'calendar',
  Event = 'event',
  // Epg = 'epg',
}

export {
  ScoreboardLayoutTypes,
  ScoreboardContext
}
