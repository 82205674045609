import { getCookie } from '@sporza/utils/cookies'
import { findParent } from '@sporza/utils/dom'

// TODO: add feature toggle including a possibility to change the version
// VRT.sporza.featureToggles.set('login', true, { version: 'latest' })

const VRT_PROFILE_VERSION = 'latest'
let hasHookedElements = false

const getEnv = () => {
  const host = window && window.location.host

  switch (host) {
    case 'dev.sporza.be':
    case 'beta-dev.sporza.be':
      return 'dev'
    case 'stag.sporza.be':
    case 'beta-stag.sporza.be':
      return 'stag'
    default:
      return 'prod'
  }
}

const version = VRT_PROFILE_VERSION
const ssoDevHost = `https://sso-dev.vrt.be/${version}`
const ssoStagHost = `https://sso-stag.vrt.be/${version}`
const ssoProdHost = `https://sso.vrt.be/${version}`

const config = {
  dev: {
    clientId: 'sporza-site-dev',
    profileEndpoint: `${ssoDevHost}/controller.min.js`,
    combinedEventHandler: `${ssoDevHost}/combinedeventhandler.min.js`,
    playerEventHandlerStyling: `${ssoDevHost}/playereventhandler.css`,
    qualifioHelper: `${ssoDevHost}/qualifiohelper.min.js`,
    userDataCookie: 'sporza-site-dev_profile_dt',
    videoTokenCookie: 'sporza-site-dev_profile_vt'
  },
  stag: {
    clientId: 'sporza-site-stag',
    profileEndpoint: `${ssoStagHost}/controller.min.js`,
    combinedEventHandler: `${ssoStagHost}/combinedeventhandler.min.js`,
    playerEventHandlerStyling: `${ssoStagHost}/playereventhandler.css`,
    qualifioHelper: `${ssoStagHost}/qualifiohelper.min.js`,
    userDataCookie: 'sporza-site-stag_profile_dt',
    videoTokenCookie: 'sporza-site-stag_profile_vt'
  },
  prod: {
    clientId: 'sporza-site',
    profileEndpoint: `${ssoProdHost}/controller.min.js`,
    combinedEventHandler: `${ssoProdHost}/combinedeventhandler.min.js`,
    playerEventHandlerStyling: `${ssoProdHost}/playereventhandler.css`,
    qualifioHelper: `${ssoProdHost}/qualifiohelper.min.js`,
    userDataCookie: 'sporza-site_profile_dt',
    videoTokenCookie: 'sporza-site_profile_vt'
  },
}

const getConfig = () => {
  return config[getEnv()]
}

// const getVideoTokenCookieName = () => getConfig().videoTokenCookie
// const getUserDataCookie = () => cookieHelper.readCookie(getConfig().userDataCookie)

const createLoginConfigObject = () => new Promise<void>(resolve => {
  if (typeof window === 'undefined')
    return

  window.VRT.SSOConfig = {
    clientId: getConfig().clientId,
    resumePage: window.location.origin + window.location.pathname + '?ndl=true'
  }
  resolve()
})

const createLoginScriptTag = () => {
  return addScript('ssoScript', getConfig().profileEndpoint)
}

const createQualifioScriptTag = () => {
  return addScript('qualifioScript', getConfig().qualifioHelper)
}

const addScript = (id: string, src: string) => {
  return new Promise<void>((resolve) => {
    if (!document.getElementById(id)) {
      const script = document.createElement('script')
      script.setAttribute('src', src)
      script.setAttribute('id', id)
      document.body.appendChild(script)
    }
    resolve()
  })
}

const getUserData = () => {
  try {
    const userDataCookie = getCookie(getConfig().userDataCookie)
    return userDataCookie && userDataCookie !== '' ? JSON.parse(userDataCookie) : undefined
  } catch (e) {
    console.warn('unable to getUserData')
    return undefined
  }
}

const getLoginElement = () => {
  const elementsByTagName = document.getElementsByTagName('sso-login')
  if (elementsByTagName.length > 0) {
    return elementsByTagName[0]
  }

  return undefined
}

const addEventListeners = () => {
  if (hasHookedElements) return

  const ssoLogin = getLoginElement()

  if (ssoLogin) {
    // data related events
    ssoLogin.addEventListener('stateChanged', (event: any) => {
      console.debug('sso stateChanged', event.detail)
    })

    document.querySelectorAll('.sso-login--notifications').forEach(menuItem => {
      menuItem.addEventListener('click', (event) => {
        event.preventDefault()
        // TODO: add this personalization pane
        // toggleFlyout()
      })
    })
  }

  hasHookedElements = true
}

const displayProfileButton = () => {
  document.body.classList.add('has-login-enabled')
  const loginButton = document.querySelector('sso-login')
  const loginParent = findParent(loginButton, 'vrt-list__item--login') as HTMLElement
  loginParent && loginParent.style.removeProperty('display')
}

const addQualifioCampaign = (campaignId: string) => {
  createQualifioScriptTag()

  window.VRT.SSOConfig = window.VRT.SSOConfig || {}

  window.VRT.SSOConfig.Qualifio = {
    campaignId,
    domain: window.location.host,
    env: getEnv() !== 'prod' ? getEnv() : undefined
  }
}

export {
  getEnv,
  addEventListeners,
  addQualifioCampaign,
  config,
  createLoginConfigObject,
  createLoginScriptTag,
  displayProfileButton,
  getConfig,
  getLoginElement,
  getUserData,
  // getUserDataCookie,
  // getVideoTokenCookieName,
}
