import type { FunctionComponent } from 'react'
import type { VotingProps } from '../../../voting'

import { useAuth, useScreenshot, useWebShareApi } from '@sporza/hooks'
import { useRef } from 'react'

import Paragraph from '../../../../../atoms/paragraph'
import Title, { TitleSize } from '../../../../../atoms/title'
import Button, { ButtonVariant } from '../../../../../molecules/button'
import { VotingPhase } from '../../../config'
import { Formation } from '../components'
import commonStyles from '../iedereen-bondscoach.module.scss'

type IedereenBondscoachResultsProps = VotingProps

const IedereenBondscoachResults: FunctionComponent<IedereenBondscoachResultsProps> = (
  {
    vote,
    handlePhaseChange,
    className
  }
) => {
  const ref = useRef(null)
  const {
    getImageBlob,
    downloadImage,
    imageExtension
  } = useScreenshot(ref)
  const { isWebShareApiSupported, share } = useWebShareApi()
  const { name } = useAuth()

  const shareResult = async () => {
    const imageBlob = await getImageBlob()

    const shareData: ShareData = {
      title: `${name ? `${name}'s` : 'mijn'} ideale team`,
      text: 'Dit is mijn ideale team',
      url: window.location.href,
      files: [
        new File([imageBlob], `${name ? `${name}` : 'mijn'}-ideale-team.${imageExtension}`, {
          type: imageBlob.type,
        }),
      ],
    }

    await share(shareData)
  }

  return <div
    className={className}
    ref={ref}
  >
    <div className={commonStyles.header}>
      <Title size={TitleSize.Large}>iedereen bondscoach<span className={commonStyles.accentPoint}>.</span></Title>
      <Paragraph>bedankt voor jouw stem!</Paragraph>
    </div>

    {
      vote
      && <Formation
        selectedFormation={vote.formation}
        players={vote.players}
      >
        {
          isWebShareApiSupported
          && <Button
            data-html2canvas-ignore="true"
            className={commonStyles.ctaButton}
            iconAfter="share"
            onClick={() => shareResult()}
          >team delen</Button>
        }

        <div>
          <Button
            data-html2canvas-ignore="true"
            variant={ButtonVariant.tertiary}
            className={commonStyles.link}
            onClick={() => handlePhaseChange && handlePhaseChange(VotingPhase.voting, null, vote?.players)}
          >
            selectie aanpassen
          </Button>

          <Button
            data-html2canvas-ignore="true"
            variant={ButtonVariant.tertiary}
            className={commonStyles.link}
            onClick={() => downloadImage()}
          >
            download afbeelding
          </Button>
        </div>
      </Formation>
    }
  </div>
}

export {
  IedereenBondscoachResults
}

export type {
  IedereenBondscoachResultsProps
}

