import type { TimelineProps } from '../../timeline'

import { useApi, useIntersectionObserver } from '@sporza/hooks'
import clsx from 'clsx'
import { formatDistance } from 'date-fns'
import nlLocale from 'date-fns/locale/nl-BE'
import Favicon from 'favico.js'
import { FunctionComponent } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Gallery } from 'react-photoswipe-gallery'

import Icon from '../../../../atoms/icon'
import Title, { TitleDirection, TitleElement, TitleSize } from '../../../../atoms/title'
import { icons } from '../../../../design-tokens/iconography/icons'
import Button from '../../../../molecules/button'
import Filter, { FilterType } from '../../../../molecules/filter'
import { Notification, NotificationType } from '../../../../molecules/notification'
import { StatusTypes } from '../../../../molecules/score'
import { onBeforeOpen, onOpen, options } from '../../../mastermedia'
import { DripLoader } from '../../components'
import DynamicEvent from '../../components/dynamic-event'
import { TimelineFilterType } from '../../config'
import { TimelineType } from '../../timeline'
import styles from '../../timeline.module.scss'

const maptoLoaderIcon = (sport: string) => {
  switch (sport) {
    case 'formula1':
      return 'formula1-wheel'
    default: {
      const isValidIcon = `sport-${sport}` in icons

      return isValidIcon
        ? `sport-${sport}`
        : 'spinner'
    }
  }
}

const TimelineDefaultComponent: FunctionComponent<TimelineProps> = (props) => {
  const {
    author = false,
    className
  } = props
  const {
    data,
    isEnabled
  } = useApi(props, {
    // keyPrefix: 'timeline'
  })

  const [expanded, setExpanded] = useState(isEnabled)
  const {
    timeline = {},
    sportApiUrl
  } = data || props
  const {
    label,
    sublabel,
    type,
    items = [],
    originalItems = [],
    autoLoad = false,
    sport,
    filter
  } = timeline

  const limit = props.status === StatusTypes.Live ? 999 : 20
  const [visibleItems, setVisibleItems] = useState(isEnabled ? items : items.slice(0, limit))
  const [viewedItems, setViewedItems] = useState(items.length)
  const [newItems, setNewItems] = useState(0)
  const [title, setTitle] = useState<string>(label)
  const [subtitle, setSubtitle] = useState<string>(sublabel)
  const [activeFilterType, setActiveFilterType] = useState<TimelineFilterType>()
  const ref = useRef(null)

  const entry = useIntersectionObserver(null, { threshold: 0.1 })
  const isVisibleTimeline = !!entry?.isIntersecting
  const hasItems = items.length > 0

  let favicon: any

  useEffect(() => {
    window.VRT = window.VRT || {}
    window.VRT.sporza = window.VRT.sporza || {}
    window.VRT.sporza.Timeline = window.VRT.sporza.Timeline || {}

    VRT.sporza.Timeline.updateIsExpanded = setExpanded
  }, [])

  useEffect(() => {
    favicon = new Favicon()

    return () => favicon?.badge(0)
  }, [Favicon])

  useMemo(() => {
    const latestTimestamp = items.find((item: any) => item.timestamp)?.timestamp

    if (latestTimestamp) {
      setSubtitle(
        'laatste update: ' +
        formatDistance(
          new Date(latestTimestamp),
          Date.now(),
          {
            addSuffix: true,
            locale: nlLocale
          }
        )
      )
    }

    const filteredItems = activeFilterType && activeFilterType !== TimelineFilterType.Default
      ? items.filter((item: any) =>
        item.filterType === activeFilterType
      )
      : items

    if (expanded) {
      setVisibleItems(filteredItems)

      if (isVisibleTimeline) {
        setViewedItems(filteredItems.length)
      } else if (isEnabled && Math.max(filteredItems.length - viewedItems, 0) !== newItems) {
        setNewItems(Math.max(filteredItems.length - viewedItems, 0))
      }
    } else {
      setVisibleItems(filteredItems.slice(0, limit))
    }
  }, [expanded, JSON.stringify(items), activeFilterType])

  useEffect(() => {
    if (isVisibleTimeline && newItems > 0) {
      setViewedItems(items.length)
      setNewItems(0)
    }
  }, [isVisibleTimeline])

  useEffect(() => {
    if (!favicon)
      return

    // if (newItems > 0) {
    //   favicon.badge(newItems)
    // } else {
    //   favicon.reset()
    // }
  }, [newItems])

  useEffect(() => {
    if (!title) {
      setTitle(label)
    }
  }, [label])

  useEffect(() => {
    const intervalId = setInterval(updateLasteUpdate, 30000)

    updateLasteUpdate()

    return () => clearInterval(intervalId)
  }, [])

  const updateLasteUpdate = () => {
    const latestTimestamp = items.find((item: any) => item.timestamp)?.timestamp

    if (latestTimestamp) {
      setSubtitle(
        'laatste update: ' +
        formatDistance(
          new Date(latestTimestamp),
          Date.now(),
          {
            addSuffix: true,
            locale: nlLocale
          }
        )
      )
    }
  }

  return <>
    <div
      key="timeline"
      className={clsx(
        styles.timeline,
        className,
        'sw-timeline'
      )}
    >
      <div className={styles.timelineHeader}>
        {
          title
          && hasItems
          && <div className={styles.timelineTitle}>
            <DripLoader/>
            <Title
              size={TitleSize.Large}
              tag={TitleElement.H2}
              subTitle={subtitle}
              direction={TitleDirection.Column}
            >{title}</Title>
          </div>
        }
        {
          filter
          && <Filter
            type={FilterType.Dropdown}
            onSelect={(activeOption) => activeOption.value && setActiveFilterType(activeOption.value as TimelineFilterType)}
            {...filter}
          />
        }
      </div>

      {
        isEnabled
        && hasItems
        && <Icon
          name={maptoLoaderIcon(sport)}
          className={styles.loader}
        />
      }

      {/* TODO: check how we can do this on a page level when all pages are migrated */}
      {/* any type of event can have images, so we need to wrap the gallery around the events */}
      {
        hasItems
          ? <Gallery withCaption onBeforeOpen={onBeforeOpen} onOpen={onOpen} options={options}>
            <div
              ref={ref}
              className={styles.events}
            >
              {
                visibleItems.map((item: any) => {
                  const key = [
                    item.id,
                    item.timestamp,
                    item.label?.label,
                    item.type,
                    item.image,
                    item.title,
                    item.text,
                    item.player?.name,
                    item.team?.name
                  ].filter((item) => item).join('-')

                  return <DynamicEvent
                    key={key}
                    {...item}
                    timelineType={type || TimelineType.Default}
                    autoLoad={autoLoad}
                  />
                })
              }
            </div>
          </Gallery>
          : author
            ? <Notification
              type={NotificationType.Info}
              label="Timeline"
              text="Timeline does not have any events yet"
            />
            : false
      }

      {
        items.length > limit
          ? <div className={styles.more}>
            <Button
              onClick={() => setExpanded(!expanded)}
              iconAfter={expanded ? 'chevron-up' : 'chevron-down'}
            >
              {expanded ? 'minder weergeven' : 'alles weergeven'}
            </Button>
          </div>
          : false
      }

      {
        author
          ? <div
            className="sw-timeline-event-data"
            data-sport-api-url={sportApiUrl}
            style={{ display: 'none' }}
          >
            {JSON.stringify(originalItems)}
          </div>
          : false
      }
    </div>

    {
      newItems > 0
        ? <Button
          key="timeline-action-button"
          className={styles.actionButton}
          iconAfter={'arrow-up'}
          onClick={() => {
            if (ref.current) {
              (ref.current as HTMLElement).scrollIntoView({ behavior: 'smooth' })
            }

            setViewedItems(items.length)
            setNewItems(0)
          }}
        >
          {
            newItems === 1
              ? '1 nieuw item'
              : `${newItems} nieuwe items`
          }
        </Button>
        : false
    }
  </>
}

export {
  TimelineDefaultComponent
}
