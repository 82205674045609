import React, { type ReactElement, FunctionComponent,PropsWithChildren } from 'react'

import { Icons } from '../../design-tokens/iconography/icons'
import { EpgLayout } from '../../organisms/epg'
import { ProgramItemProps } from '../program-item'

import ProgramListHeader from './layout/header'
import ProgramListDefault from './layout'

enum ProgramListLayoutTypes {
  Default = 'default',
  Header = 'header'
}

interface ProgramListProps extends PropsWithChildren {
  title?: string
  subTitle?: string
  icon?: Icons | string
  items: ProgramItemProps[]
  layout?: ProgramListLayoutTypes | EpgLayout
  start?: Date
  end?: Date,
  darkMode?: boolean,
  epgLayout?: EpgLayout
}

const ProgramList: FunctionComponent<ProgramListProps> = (
  props
) => {
  const layout = props.layout || ProgramListLayoutTypes.Default

  const programListLayout: {[Key in ProgramListLayoutTypes as string]: ReactElement} = {
    'default': <ProgramListDefault {...props} />,
    'header': <ProgramListHeader {...props} />
  }

  return programListLayout[layout]
}

export default ProgramList

export type {
  ProgramListProps
}

export {
  ProgramListLayoutTypes
}
