import type { FunctionComponent, Ref } from 'react'
import type { CardProps } from '../card'
import type { StoryCard } from '../story-card'

import { useListClick, useListImpression } from '@sporza/tracking'
import { useRef } from 'react'

import { Icons } from '../../design-tokens/iconography/icons'
import { StatusTypes } from '../../molecules/score'
import { MediaplayerComponentProps } from '../mediaplayer'

import LivestreamCardCompact from './layout/compact'
import LivestreamCardDetailed from './layout/detailed'
import LivestreamCardVertical from './layout/vertical'
import LivestreamCardDefault from './layout'

interface LivestreamCardComponentProps {
  componentProps: LivestreamCardProps
  componentType: string
}

interface LivestreamCardProps extends CardProps {
  layout?: LivestreamCardLayout
  icon?: Icons
  title?: string
  subtitle?: string
  description?: string
  image?: string
  imageTitle?: string
  button?: {
    title?: string
    icon?: Icons
    status?: StatusTypes
  },
  time?: {
    text?: string
    icon?: Icons
    status?: StatusTypes
  },
  label?: {
    title?: string
  },
  innerRef?: Ref<HTMLElement>,
  progress?: number
  link?: string
  livestream?: MediaplayerComponentProps
  channels?: {
    title?: string
    href?: string
    iconAfter?: Icons
  }[]
  shareLinks?: {
    iconBefore?: Icons | string
    ariaLabel?: string
    href?: string
  }[]
  match?: StoryCard
}

enum LivestreamCardLayout {
  Default = 'default',
  Vertical = 'vertical',
  Compact = 'compact',
  Detailed = 'detailed'
}

const layoutComponents = {
  [LivestreamCardLayout.Default]: LivestreamCardDefault,
  [LivestreamCardLayout.Vertical]: LivestreamCardVertical,
  [LivestreamCardLayout.Compact]: LivestreamCardCompact,
  [LivestreamCardLayout.Detailed]: LivestreamCardDetailed,
}

const LivestreamCard: FunctionComponent<LivestreamCardProps> = (
  props
) => {
  const ref = useRef(null)

  const {
    ebaData,
    layout = LivestreamCardLayout.Default
  } = props

  useListClick({
    ref,
    ...ebaData
  })

  useListImpression(
    {
      ref,
      ...ebaData
    }
  )

  const Layout = layoutComponents[layout as keyof typeof layoutComponents]

  return <Layout innerRef={ref} {...props} />
}

export type {
  LivestreamCardProps,
  LivestreamCardComponentProps
}

export {
  LivestreamCard,
  LivestreamCardLayout
}
