import type { FunctionComponent } from 'react'

import { TitleElement } from '../../../atoms/title'
import { ScoreboardComponentProps } from '../../scoreboard'
import { ScoreboardContext } from '../../scoreboard/config'
import { Section, SectionLayout, SectionProps } from '../../section'

interface MatchdayCardsProps {
  title?: string
  link?: string
  items: ScoreboardComponentProps[]
  tag?: string | TitleElement,
  scoreboardContext?: ScoreboardContext
}

const MatchdayCards: FunctionComponent<MatchdayCardsProps> = (
  {
    scoreboardContext,
    ...props
  }
) => {
  return <Section
    {...props as SectionProps}
    context={scoreboardContext || ScoreboardContext.Matchday}
    layout={SectionLayout.Grid3ColumnOnMobileSlider}
   />
}

export default MatchdayCards

export type {
  MatchdayCardsProps
}
