import type { FunctionComponent } from 'react'
import type { ScoreboardRoundsProps } from '../default'

import clsx from 'clsx'

import Icon from '../../../../atoms/icon'
import Paragraph from '../../../../atoms/paragraph'
import Title, { TitleSize } from '../../../../atoms/title'
import FieldTimeline from '../../../../molecules/field-timeline/field-timeline'
import Intermediates from '../../../../molecules/intermediates/intermediates'
import Score, { ScoreLayoutTypes, StatusTypes } from '../../../../molecules/score'
// import { Accordion, AccordionLayouts } from '../../../accordion'
import styles from '../../scoreboard.module.scss'

const RoundsCard: FunctionComponent<ScoreboardRoundsProps> = (
  {
    competition,
    competitionName,
    eventSets,
    groups,
    intermediates,
    isLastItem = false,
    label,
    location,
    rounds,
    roundsToGo,
    ruler,
    showLinks,
    sport,
    startLabel,
    status,
    temperature,
    type,
    typeIcon,
    weatherIcon,
    winner,
  }
) => {
  const Tag = showLinks && winner?.url ? 'a' : 'div'

  return <>
    <div className={clsx(
      styles.scoreboardContent,
      isLastItem && styles.lastItem
    )}>
      <div className={styles.info}>
        <Title desktopSize={TitleSize.Medium} size={TitleSize.XSmall}>{competitionName}</Title>
        <Paragraph className={styles.list}>
          {competition && <Paragraph className={styles.listItem}>{competition}</Paragraph>}
          {type && type !== 'Veldrijden' && <Paragraph className={styles.listItem}>{typeIcon && <Icon ariaHidden name={typeIcon} />}{type}</Paragraph>}
          {temperature && <Paragraph className={styles.listItem}>{weatherIcon && <Icon ariaHidden name={weatherIcon} />}{temperature} &deg;C</Paragraph>}
          {rounds && <Paragraph className={styles.listItem}><Icon ariaHidden name={sport === 'cycling' ? 'cycling-rounds' : 'formula1-laps'} />{rounds} rondes</Paragraph>}
        </Paragraph>
        {startLabel && <Paragraph>{startLabel}</Paragraph>}
        {location && <Paragraph><Icon ariaHidden name={'location'} />{location}</Paragraph>}
        {winner?.name && <Tag aria-label={`winnaar ${winner.name}`} className={styles.winner} href={showLinks ? winner.url : undefined}><Paragraph weight={'Bold'}><Icon name={'cycling-hotseat'} />{winner.name}</Paragraph></Tag>}
      </div>
      <Score layout={ScoreLayoutTypes.Detailed} sport={sport} roundsToGo={roundsToGo} status={status} label={label} />
    </div>
    {eventSets && <FieldTimeline status={status as StatusTypes} eventSets={eventSets} ruler={ruler} />}
    {/*{groups && <Accordion items={groups} layout={AccordionLayouts.Groups}/>}*/}
    {intermediates && <Intermediates {...intermediates} />}
  </>
}

export default RoundsCard

export type {
  ScoreboardRoundsProps
}
