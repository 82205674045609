import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import Caption from '../../../atoms/caption'
import Paragraph, { ParagraphWeight } from '../../../atoms/paragraph'
import Title, { TitleSize } from '../../../atoms/title'
import Button, { ButtonProps } from '../../../molecules/button'
import Image from '../../../molecules/image'
import Pill from '../../../molecules/pill'

import styles from './custom.cell.module.scss'

interface CellWithSubTitleProps {
  title: string,
  subTitle: string,
  channels?: any,
  sport?: string,
  time?: string,
  image?: string,
  isActive?: boolean,
  isLive?: boolean
  href?: string
  options?: any
  darkMode?: any
}

const CellWithSubTitle: FunctionComponent<CellWithSubTitleProps> = (
  {
    title,
    subTitle,
    sport,
    image,
    channels,
    time,
    isActive,
    isLive,
    href,
    options,
    darkMode
  }
) => {
  const TagElement = href ? 'a' : 'div'

  return <TagElement
    href={href}
    className={clsx(
    styles.subTitleWrapper,
    options?.size === 'large' && styles.subTitleWrapperLarge,
    isActive && styles.active,
    isLive && styles.live
  )}>
    {options?.size === 'large' ?
      <>
        {time && <Paragraph weight={ParagraphWeight.Medium} className={styles.time} darkMode={darkMode}>{time}</Paragraph>}
        <div className={styles.subTitle}>
          <Title darkMode={darkMode} size={TitleSize.Medium}>
            {title}
          </Title>
           <div className={styles.noWrap}>
            {sport && <Paragraph className={styles.subSubTitle} darkMode={darkMode}>
              {sport}
            </Paragraph>}
            {subTitle && <Paragraph className={styles.subSubTitle} darkMode={darkMode}>
              {subTitle}
            </Paragraph>}
          </div>
          {channels && <div className={clsx(
            styles.channels,
            darkMode && styles.darkMode
          )}>
            {channels?.map((channel: ButtonProps, index: number) => <Button
              key={`${channel.title}-${index}`}
              className={styles.channel}
              {...channel}
              iconDarkMode={true}>
              {channel.title}
            </Button>)}
          </div>}
        </div>
      </> : <>
        {image && <div className={styles.image}>
          <Image src={image} />
        </div>}
        <div className={styles.subTitle}>
          <Title size={TitleSize.XSmall}>
            {title}
            {isLive && <Pill>nu live</Pill>}
          </Title>
          <Caption>
            {subTitle}
          </Caption>
        </div>
      </>
    }
  </TagElement>
}

export default CellWithSubTitle
