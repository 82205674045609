import type { ClassDictionary, ClassValue } from 'clsx'
import type { FunctionComponent, PropsWithChildren, RefObject } from 'react'
import type { BannerClickArguments } from '@sporza/tracking/events'

import { getPathName, getPermalinkId } from '@sporza/utils/urls'
import { camelCase } from 'change-case'
import clsx from 'clsx'

import Button from '../../../molecules/button'
import { Podium } from '../components'
import { trackBannerClick } from '../wielermanager'

import styles from './container.module.scss'

interface WielerManagerContainerProps extends PropsWithChildren {
  widgetName?: string
  source?: string
  innerRef?: RefObject<Element>
  bannerContext?: BannerClickArguments
  className?: ClassValue
  ctaUrl?: string
  year?: string,
  containerQueries?: ClassDictionary
  playerImageUrl?: string
  playerImageUrls?: string[]
  isLive?: boolean
  showSponsor?: boolean
  match?: any
  designSystemBaseUrl?: string
}

const WielerManagerContainer: FunctionComponent<WielerManagerContainerProps> = (
  {
    widgetName,
    source,
    innerRef,
    className,
    ctaUrl,
    year = new Date().getFullYear(),
    containerQueries,
    children,
    playerImageUrl,
    playerImageUrls,
    isLive,
    showSponsor,
    bannerContext,
    match,
    designSystemBaseUrl
  }
) => {
  const Tag = isLive ? 'a' : 'div'
  return <Tag
    ref={innerRef}
    className={clsx(
      styles.container,
      className,
      containerQueries,
      source && styles[camelCase(source)],
      isLive && styles.isLive,
      ctaUrl && styles.hasCta,
      playerImageUrls?.length && styles.hasCustomPodium,
    )}
    href={isLive ? ctaUrl : undefined}
    onClick={() => {
      trackBannerClick({
        ...bannerContext,
        targetPageUrl: match.externalUrl,
        targetPageName: getPathName(match.externalUrl),
        targetPageId: getPermalinkId(match.externalUrl)
      })
      return true
    }}
  >
    <div className={styles.image}>
      {
        playerImageUrl && !playerImageUrls?.length
        && <div
          className={styles.imageBefore}
          style={{ backgroundImage: `url(${playerImageUrl})` }} >
          </div>
      }
      {
        playerImageUrls && playerImageUrls.length > 0
        && <Podium
          className={styles.customPodium}
          imageUrls={playerImageUrls}
          designSystemBaseUrl={designSystemBaseUrl}
        />
      }
      {
        ctaUrl
          ? <Button
            className={styles.callToAction}
            iconBefore={isLive ? undefined : 'sport-cycling-alt-2'}
            href={isLive ? undefined : ctaUrl}
          >{
            isLive
              ? 'volg nu live'
              : `${widgetName} ${year}`
          }</Button>
          : null
      }
      {
        showSponsor
          ? <div className={styles.sponsor}></div>
          : null
      }
    </div>
    <div className={styles.content}>
      {children}
    </div>
  </Tag>
}

export default WielerManagerContainer

export type {
  WielerManagerContainerProps
}
