import type { FunctionComponent, PropsWithChildren } from 'react'

import clsx from 'clsx'

import styles from './paragraph.module.scss'

enum ParagraphWeight {
  Medium = 'Medium',
  Bold = 'Bold'
}

interface ParagraphProps extends PropsWithChildren {
  text?: string
  weight?: string | ParagraphWeight
  desktopWeight?: string | ParagraphWeight
  insetBox?: boolean
  className?: string
  darkMode?: boolean
}

const Paragraph: FunctionComponent<ParagraphProps> = (
  {
    text,
    weight,
    desktopWeight,
    insetBox = false,
    children,
    className,
    darkMode
  }
) => {
  const TagElement = 'div'
  const classNames = clsx(
    styles.paragraph,
    weight === ParagraphWeight.Medium && styles.paragraphMedium,
    weight === ParagraphWeight.Bold && styles.paragraphBold,
    desktopWeight === ParagraphWeight.Medium && styles.paragraphMediumDesktop,
    desktopWeight === ParagraphWeight.Bold && styles.paragraphBoldDesktop,
    insetBox && styles.insetBox,
    className,
    darkMode && styles.paragraphDark
  )

  return text ? <TagElement
    className={classNames}
    dangerouslySetInnerHTML={{ __html: text || children }}
  /> : <TagElement
    className={classNames}
  >
    {children}
  </TagElement>
}

export default Paragraph

export {
  ParagraphWeight
}

export type {
  ParagraphProps
}
