export const setStorage = (key:string, array: object) => typeof localStorage !== 'undefined' && localStorage.setItem(key, JSON.stringify(array))

export const getStorage = (key: string) => {
    if (typeof localStorage !== 'undefined' && localStorage.getItem(key)){
        const data = JSON.parse(''+localStorage.getItem(key))
        if (data) return data.length > 0
          ? data.sort((a: any, b: any) => (a.timestamp > b.timestamp) ? -1 : 1)
          : data
    }
    return []
}

export const removeStorage = (key: string) => {
    setStorage(key, [])
    window.location.reload()
}
