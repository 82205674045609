import type { FunctionComponent } from 'react'
import type { TagProps } from '../../molecules/tag'
import type { CardProps } from '../card'

import { VRTBrand } from '@sporza/config'
import { useListClick, useListImpression } from '@sporza/tracking'
import clsx from 'clsx'
import { useRef } from 'react'

import Caption from '../../atoms/caption'
import Category from '../../atoms/category'
import Icon from '../../atoms/icon'
import Logo from '../../atoms/logo'
import { mapBrandToLogo } from '../../atoms/logo/svgs'
import Paragraph from '../../atoms/paragraph'
import Title from '../../atoms/title'
import Image, { ImageLayout } from '../../molecules/image'
import Tag from '../../molecules/tag'
import Card from '../card'
import List from '../list'

import styles from './podcast-episode-card.module.scss'

interface PodcastEpisodeCardComponentProps {
  componentType: string
  componentProps: PodcastEpisodeCardProps
}

interface PodcastEpisodeCardProps extends CardProps {
  label?: string
  title?: string
  text?: string
  image?: string
  tags?: TagProps[]
  brand?: VRTBrand | string
}

const PodcastEpisodeCard: FunctionComponent<PodcastEpisodeCardProps> = (
  {
    label,
    link,
    target,
    title,
    text,
    image,
    tags,
    withHover = true,
    className,
    brand,
    ebaData = {}
  }
) => {
  const ref = useRef(null)
  const brandLogo = brand && mapBrandToLogo(brand)

  useListClick({
    ref,
    ...ebaData
  })

  useListImpression(
    {
      ref,
      ...ebaData
    }
  )

  return <Card
    link={link}
    target={target}
    className={clsx(
      styles.podcastEpisodeCard,
      className
    )}
    withHover={withHover}
    innerRef={ref}
  >
    <div className={styles.imageContainer}>
      <Image
        src={image}
        alt=''
        layout={ImageLayout.Square}
        className={styles.image}
      />
    </div>
    <div className={styles.top}>
      <Category className={styles.label}>{label}</Category>
      <Title size={'XSmall'} desktopSize={'Small'}>{title}</Title>
    </div>
    <div className={styles.bottom}>
      <Paragraph className={styles.text}>{text}</Paragraph>
      <div className={styles.footer}>

        {
          brandLogo
            ? <Logo
              logo={brandLogo}
              className={styles.logo}
            />
            : <Icon
              name="play-video-solid"
              withBackground={true}
              className={styles.icon}
            />
        }
        <List className={styles.tags}>
          {tags?.map((tag: any, index) =>
            <Tag key={`${index}-${tag.text}`} {...tag} />
          )}
        </List>
      </div>
    </div>
  </Card>
}

export {
  PodcastEpisodeCard
}

export type {
  PodcastEpisodeCardProps,
  PodcastEpisodeCardComponentProps
}
