const mapPostVoteToLineup = (vote: any, options: any): any => {
  return {
    formation: vote.motivation,
    players: vote?.entries?.entry?.selection
      ?.map((id: string) => {
        const player = options.find((player: any) => player.interactionOptionId === id)

        return {
          ...player,
          selected: true
        }
      })
  }
}

const mapGetVoteToLineup = (vote: any, options: any): any => {
  return {
    formation: vote.motivation,
    players: vote?.entry?.selection
      ?.map((id: string) => {
        const player = options.find((player: any) => player.interactionOptionId === id)

        return {
          ...player,
          selected: true
        }
      })
  }
}

export {
  mapPostVoteToLineup,
  mapGetVoteToLineup
}
