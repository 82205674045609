import type { FunctionComponent } from 'react'

import clsx from 'clsx'
import { Children, useState } from 'react'

import Hidden from '../../../atoms/hidden'
import { TitleElement } from '../../../atoms/title'
import { Advertisement } from '../../../molecules/advertisement'
import Button from '../../../molecules/button'
import List, { ListAlignment } from '../../list'
import { SectionBackground, SectionLayout, SectionProps, SectionSpacing } from '../index'

import SectionCommon from './common'
import commonStyles from './common.module.scss'
import styles from './section-grid.module.scss'

type SectionStaticProps = Omit<SectionProps, 'items' | 'imageLayout'>

const SectionStatic: FunctionComponent<SectionStaticProps> = (
  {
    title,
    text,
    link,
    linkAction,
    linkText,
    layout = SectionLayout.Grid2Column,
    background = SectionBackground.Default,
    darkMode,
    children,
    spacing,
    analyticsId,
    componentType,
    className,
    gridCssModuleClassNames,
    isExpandable,
    limit,
    headerActions,
    innerRef,
    filter,
    handleFilter,
    ariaLabelledBy
  }
) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const isDarkMode = darkMode || background === SectionBackground.Dark

  let items = Children.toArray(children)

  if (isExpandable && !expanded) {
    items = items?.slice(0, limit)
  }

  return <SectionCommon
    innerRef={innerRef}
    analyticsId={analyticsId}
    title={title}
    text={text}
    link={link}
    linkAction={linkAction}
    linkText={linkText}
    className={clsx(
      className,
      commonStyles.section,
      background === SectionBackground.Dark && commonStyles.backgroundDark,
      background === SectionBackground.Light && commonStyles.backgroundLight,
      background === SectionBackground.Grey && commonStyles.backgroundGrey,
      background === SectionBackground.Gradient && commonStyles.backgroundGradient,
      layout === SectionLayout.Grid1ColumnOverflow && commonStyles.stretch,
      componentType === 'related-article' && commonStyles.relatedArticle,
      isDarkMode && commonStyles.darkMode,
      className
    )}
    headerActions={headerActions}
    filter={filter}
    handleFilter={handleFilter}
    ariaLabelledBy={ariaLabelledBy}
  >
    {
      <div className={clsx(
        styles.grid,
        layout === SectionLayout.Grid1ColumnOverflow && styles.gridGrid1ColumnOverflow,
        layout === SectionLayout.Grid2Column && styles.gridGrid2Column,
        layout === SectionLayout.Grid1Column && styles.gridGrid1Column,
        layout === SectionLayout.Grid2ColumnSquare && styles.gridGrid2ColumnSquare,
        layout === SectionLayout.Grid3Column && styles.gridGrid3Column,
        layout === SectionLayout.Grid3ColumnScroll && styles.gridGrid3ColumnScroll,
        layout === SectionLayout.Grid4Column && styles.gridGrid4Column,
        layout === SectionLayout.Grid5Column && styles.gridGrid5Column,
        layout === SectionLayout.Grid5ColumnSquare && styles.gridGrid5ColumnSquare,
        layout === SectionLayout.Grid6ColumnSquare && styles.gridGrid6ColumnSquare,
        layout === SectionLayout.Grid3x3Column && styles.gridGrid3X3Column,
        layout === SectionLayout.Grid3x3ColumnAds && styles.gridGrid3X3ColumnAds,
        layout === SectionLayout.Grid3x4Column && styles.gridGrid3X4Column,
        layout === SectionLayout.Grid2x4ColumnMatches && styles.gridGrid2X4ColumnMatches,
        spacing === SectionSpacing.Narrow && styles.gridNarrow,
        gridCssModuleClassNames?.length ? gridCssModuleClassNames.map((className) => styles[className]) : null,
      )}>
        {items}
        {layout === SectionLayout.Grid3x3ColumnAds && <div className={styles.ad}><Advertisement adType={'halfpage'} /></div>}
        {isExpandable &&
          <List align={ListAlignment.Center}>
            <Button
              onClick={() => setExpanded(!expanded)}
              iconAfter={expanded ? 'chevron-up' : 'chevron-down'}
            >
              {expanded ? 'minder' : 'meer'}
            </Button>
          </List>
        }
      </div>
    }
  </SectionCommon>
}

export type {
  SectionStaticProps
}
export default SectionStatic
