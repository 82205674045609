import { capitalize } from '@sporza/utils/strings'
import clsx from 'clsx'
import React, { FunctionComponent } from 'react'

import ProgramItem from '../../program-item'
import { ProgramListProps } from '../index'
import styles from '../program-list.module.scss'

const ProgramListDefault: FunctionComponent<ProgramListProps> = ({
  start,
  end,
  items,
  epgLayout,
  darkMode
}) => {
  return <ol className={clsx(
    styles.programList,
    epgLayout && styles[`programList${capitalize(epgLayout)}`],
    darkMode && styles.darkMode
  )}>
    <li className={styles.background}></li>
    {items.map((item, index) => <ProgramItem
      listStart={start}
      listEnd={end}
      key={`${index}-${item.title}-${item.subTitle}`}
      {...item}
      layout={epgLayout}
      darkMode={darkMode}
    />)}
  </ol>
}

export default ProgramListDefault
